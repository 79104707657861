<template>
  <div v-if="getVisibleDoWin">
    <div style="top: -12px;" class="notification-popup notification-popup-dowin">
      <div class="notification-popup-content notification-popup-content-dowin">
        <div class="close" @click.capture="closePopup('XButtonClose')" />
        <div @click="goToDoWinUrl">
          <img :src="getDoWinImage" alt="notification icon" />
          <p class="text top">Görevleri Tamamla</p>
          <p class="text bottom">{{ getDoWinPoint }} Puan Kazan</p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
export default {
  name: 'DoWinPopup',
  computed: {
    ...mapGetters('app', ['getPopups']),
    getVisibleDoWin() {
      return this.getPopups.popupId == 4 && this.$route.fullPath == '/secure/feed';
    },
    getDoWinImage() {
      return this.getPopups?.popupDetail?.image ?? '';
    },
    getDoWinPoint() {
      return this.getPopups?.popupDetail?.point ?? 0;
    },
  },
  methods: {
    setEventData(isClick) {
      return {
        event: 'cEvent',
        category: 'Pop Up',
        action: isClick ? 'Click' : 'Close',
        label: 'DoWin',
        value: 0,
        isNonInteraction: false,
      };
    },
    closePopup(action) {
      const eventData = this.setEventData(false);
      this.$emit('closePopup', eventData, { type: action });
    },
    goToDoWinUrl() {
      this.$router.push(`${this.getPopups.popupDetail.url}`);

      const eventData = this.setEventData(true);
      this.$emit('closePopup', eventData, { type: 'LinkClick' });
    },
  },
};
</script>
<style lang="scss" scoped>
.shape {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 11;
  background-color: #000;
  opacity: 0.8;
}
.notification-popup {
  position: absolute;
  right: -10px;
  left: -10px;
  bottom: -12px;
  background-color: #fff;
  z-index: -1;
  border-radius: 5px;
  cursor: pointer;
  &-content {
    position: absolute;
    top: -250px;
    left: 0;
    width: 323px;
    .text {
      position: absolute;
      left: 20px;
      &.top {
        bottom: 75px;
        color: #ffbfbf;
      }
      &.bottom {
        bottom: 42px;
        color: #fff;
        font-size: 24px;
      }
    }
    &-dowin {
      top: 50%;
      transform: translateY(-50%);
      left: -342px;
      .text {
        left: 16px;
        &.top {
          bottom: 50px;
          color: #23303d;
        }
        &.bottom {
          bottom: 20px;
          color: #fc9507;
          font-size: 24px;
        }
      }
    }
    .close {
      position: absolute;
      left: 0;
      top: 0;
      color: #fff;
      width: 45px;
      height: 45px;
      cursor: pointer;
      transition: opacity 0.2s ease;
      &:after,
      &:before {
        content: '';
        position: absolute;
        top: 20px;
        left: 15px;
        width: 16px;
        height: 2px;
        background-color: #fff;
      }
      &:after {
        transform: rotate(45deg);
      }
      &:before {
        transform: rotate(135deg);
      }
      &:hover {
        opacity: 0.9;
      }
    }
  }
}
</style>
