import { render, staticRenderFns } from "./DoWinPopup.vue?vue&type=template&id=587dff95&scoped=true&"
import script from "./DoWinPopup.vue?vue&type=script&lang=js&"
export * from "./DoWinPopup.vue?vue&type=script&lang=js&"
import style0 from "./DoWinPopup.vue?vue&type=style&index=0&id=587dff95&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "587dff95",
  null
  
)

export default component.exports